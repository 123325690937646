import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "./superAdmin.scss";

function FilterBtn({ filters, setFilters, onApplyFilters }) {
  const [showFilters, setShowFilters] = useState(false);


  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleClose = () => {
    setFilters({
      userId: "",
      userName: "",
      contact: "",
      emailId: "",
      location: "",
    })
    setShowFilters(false);
  };

  return (
    <Box className="filter-container">
      <Button
        variant="contained"
        className="filter-button"
        onClick={!showFilters ? toggleFilters : handleClose }
      >
        {!showFilters ? "Filter" : "Close"}
      </Button>
      {showFilters && (
        <Box className="filter-options">
          <TextField
            label="User ID"
            variant="outlined"
            size="small"
            className="text-field"
            value={filters.userId || ""}
            onChange={(e) => handleChange("userId", e.target.value)}
          />
          <TextField
            label="User Name"
            variant="outlined"
            size="small"
            className="text-field"
            value={filters.userName || ""}
            onChange={(e) => handleChange("userName", e.target.value)}
          />
          <TextField
            label="Contact"
            variant="outlined"
            size="small"
            className="text-field"
            value={filters.contact || ""}
            onChange={(e) => handleChange("contact", e.target.value)}
          />
          <TextField
            label="Email ID"
            variant="outlined"
            size="small"
            className="text-field"
            value={filters.emailId || ""}
            onChange={(e) => handleChange("emailId", e.target.value)}
          />
          <TextField
            label="Location"
            variant="outlined"
            size="small"
            className="text-field"
            value={filters.location || ""}
            onChange={(e) => handleChange("location", e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
}

export default FilterBtn;
